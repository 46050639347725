import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './steps2.css'

const Steps2 = (props) => {
  return (
    <div className="steps2-container1 thq-section-padding">
      <div className="steps2-max-width thq-section-max-width">
        <div className="steps2-container2 thq-grid-2">
          <div className="steps2-section-header">
            <h2 className="thq-heading-2">
              <span>
                How to Buy
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="steps2-text12">$BOOBA</span>
              <span>?</span>
            </h2>
            <p className="thq-body-large">
              If you dont know how to ape yet, you probably shouldn&apos;t be
              here...
            </p>
            <div className="steps2-actions">
              <a
                href="https://pump.fun/coin/6yhqRXSYRgToouhjUsBD8rtscJaqKm8rUGLL1Ua7pump"
                target="_blank"
                rel="noreferrer noopener"
                className="steps2-button thq-button-animated thq-button-filled"
              >
                <span className="thq-body-small">
                  <span>
                    Buy
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="steps2-text17">$BOOBA</span>
                </span>
              </a>
            </div>
          </div>
          <div className="steps2-container3">
            <div className="steps2-container4 thq-card">
              <h2 className="thq-heading-2">
                {props.step1Title ?? (
                  <Fragment>
                    <span className="steps2-text35">Get a Wallet</span>
                  </Fragment>
                )}
              </h2>
              <span className="steps2-text19 thq-body-small">
                {props.step1Description ?? (
                  <Fragment>
                    <span className="steps2-text40">
                      <span>
                        Download phantom wallet from
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <a
                        href="https://phantom.app/download"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="steps2-link2"
                      >
                        phantom.app
                      </a>
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text20 thq-heading-3">01</label>
            </div>
            <div className="steps2-container5 thq-card">
              <h2 className="thq-heading-2">
                {props.step2Title ?? (
                  <Fragment>
                    <span className="steps2-text37">Get Gas</span>
                  </Fragment>
                )}
              </h2>
              <span className="steps2-text22 thq-body-small">
                {props.step2Description ?? (
                  <Fragment>
                    <span className="steps2-text39">
                      Get some $SOL from a CEX like Binance, Coinbase or Kucoin
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text23 thq-heading-3">02</label>
            </div>
            <div className="steps2-container6 thq-card">
              <h2 className="thq-heading-2">
                {props.step3Title ?? (
                  <Fragment>
                    <span className="steps2-text36">Ape the Coin</span>
                  </Fragment>
                )}
              </h2>
              <a
                href="https://pump.fun/coin/6yhqRXSYRgToouhjUsBD8rtscJaqKm8rUGLL1Ua7pump"
                target="_blank"
                rel="noreferrer noopener"
                className="steps2-link1 thq-body-small"
              >
                {props.step3Description ?? (
                  <Fragment>
                    <span className="steps2-text29">
                      <span>
                        Buy the coin on
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="steps2-text31">pump.fun</span>
                    </span>
                  </Fragment>
                )}
              </a>
              <label className="steps2-text25 thq-heading-3">03</label>
            </div>
            <div className="steps2-container7 thq-card">
              <h2 className="thq-heading-2">
                {props.step4Title ?? (
                  <Fragment>
                    <span className="steps2-text32">
                      <span>Send us Some Titty Pics</span>
                      <br></br>
                    </span>
                  </Fragment>
                )}
              </h2>
              <span className="steps2-text27 thq-body-small">
                {props.step4Description ?? (
                  <Fragment>
                    <span className="steps2-text38">
                      Not totally necessary, but $BOOBA has requested some titty
                      pics
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text28 thq-heading-3">04</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Steps2.defaultProps = {
  step3Description: undefined,
  step4Title: undefined,
  step1Title: undefined,
  step3Title: undefined,
  step2Title: undefined,
  step4Description: undefined,
  step2Description: undefined,
  step1Description: undefined,
}

Steps2.propTypes = {
  step3Description: PropTypes.element,
  step4Title: PropTypes.element,
  step1Title: PropTypes.element,
  step3Title: PropTypes.element,
  step2Title: PropTypes.element,
  step4Description: PropTypes.element,
  step2Description: PropTypes.element,
  step1Description: PropTypes.element,
}

export default Steps2
