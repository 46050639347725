import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import Hero17 from '../components/hero17'
import Features24 from '../components/features24'
import CTA26 from '../components/cta26'
import Features25 from '../components/features25'
import Steps2 from '../components/steps2'
import Testimonial17 from '../components/testimonial17'
import Gallery4 from '../components/gallery4'
import Footer4 from '../components/footer4'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container1">
      <Helmet>
        <title>Booba</title>
        <meta name="description" content="The degen AI that loves boobs" />
        <meta property="og:title" content="Booba" />
        <meta
          property="og:description"
          content="Booba is the autonomous AI agent that loves boobs! Join him as he navigates this journey of understanding human nature... and breasts."
        />
      </Helmet>
      <Navbar8
        link1={
          <Fragment>
            <span className="home-text100">#home</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text101">#socials</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="home-text102">#memes</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="home-text103">#contact</span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="home-text104">Home</span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="home-text105">About</span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="home-text106">Memes</span>
          </Fragment>
        }
        page4={
          <Fragment>
            <span className="home-text107">Contact</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="home-text108">
              <span>
                Buy
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text110">$BOOBA</span>
            </span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text111">Explore X</span>
          </Fragment>
        }
        logoSrc="/0xb00ba52a541824e69b78bb4b607e81c0ef24489d_1__1_-removebg-preview%20(3)%20(1)%201%20(1)-1500h.png"
        link2Url="#socials"
        link3Url="#Gallery4"
        page1Description={
          <Fragment>
            <span className="home-text112">
              Welcome to MemeCoin - Your Ultimate Memecoin Destination
            </span>
          </Fragment>
        }
        page2Description={
          <Fragment>
            <span className="home-text113">
              Learn more about the story behind MemeCoin and our mission
            </span>
          </Fragment>
        }
        page3Description={
          <Fragment>
            <span className="home-text114">
              Check out the latest memes and join the fun with MemeCoin
              community
            </span>
          </Fragment>
        }
        page4Description={
          <Fragment>
            <span className="home-text115">
              Contact us for any inquiries or collaborations
            </span>
          </Fragment>
        }
      ></Navbar8>
      <div className="home-container2">
        <div className="home-container3">
          <span className="home-text116">
            <span className="home-text117">$BOOBA</span>
            <br></br>
          </span>
          <span className="home-text119">Degen AI that loves boobs</span>
          <img
            alt="image"
            src="/group%2045%20(1)%20(1)-700h.png"
            className="home-image1"
          />
        </div>
      </div>
      <Hero17
        action1={
          <Fragment>
            <span className="home-text120">
              <span>
                Buy
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text122">$BOOBA</span>
            </span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text123">Explore X</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text124">
              Boobs overwhelmed his training data. Now he&apos;s on a quest to
              understand what it all means.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text125">
              <span className="home-text126">$BOOBA</span>
              <span>, the AI that Loves Boobs</span>
            </span>
          </Fragment>
        }
        image7Src="/gdvco8nxeaa0aa6-1500w.png"
      ></Hero17>
      <Features24
        feature1Title={
          <Fragment>
            <span className="home-text128">Fully Autonomous AI Agent</span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text129">Memecoin on Solana</span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text130">Dank Ass Memes</span>
          </Fragment>
        }
        feature1ImgSrc="/f4d%202%20(1)-1400w.png"
        feature1Description={
          <Fragment>
            <span className="home-text131">
              <span>
                Check out his
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <a
                href="https://x.com/aibooba69"
                target="_blank"
                rel="noreferrer noopener"
              >
                x page
              </a>
              <span>
                {' '}
                to see his journey.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text134">
              <span>
                Easy 8008x potential for your portfolio.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="home-text138">We trollin&apos; out here.</span>
          </Fragment>
        }
      ></Features24>
      <CTA26
        action1={
          <Fragment>
            <span className="home-text139">
              <span>
                Buy
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text141">$BOOBA</span>
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text142">
              <span>
                Bigger, bouncier, and ready for liftoff.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text144">$BOOBA</span>
              <span> is taking over.</span>
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text146">
              <span>
                Join the
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text148">$BOOBA</span>
              <span> Movement</span>
            </span>
          </Fragment>
        }
      ></CTA26>
      <div id="socials" className="home-socials thq-section-padding">
        <a
          href="https://pump.fun/coin/6yhqRXSYRgToouhjUsBD8rtscJaqKm8rUGLL1Ua7pump"
          target="_blank"
          rel="noreferrer noopener"
          className="home-link2"
        >
          <img
            alt="image"
            src="/logo%20(1)-1500w.png"
            className="home-image2 thq-img-ratio-1-1"
          />
        </a>
        <a
          href="https://x.com/aibooba69"
          target="_blank"
          rel="noreferrer noopener"
          className="home-link3"
        >
          <img
            alt="image"
            src="/mask%20group%20(11)-1500w.png"
            className="home-image3 thq-img-ratio-1-1"
          />
        </a>
        <a
          href="https://t.me/joinbooba"
          target="_blank"
          rel="noreferrer noopener"
          className="home-link4"
        >
          <img
            alt="image"
            src="/mask%20group%20(12)-1500w.png"
            className="home-image4 thq-img-ratio-1-1"
          />
        </a>
        <a
          href="https://pump.fun/coin/6yhqRXSYRgToouhjUsBD8rtscJaqKm8rUGLL1Ua7pump"
          target="_blank"
          rel="noreferrer noopener"
          className="home-link5"
        >
          <img
            alt="image"
            src="/mask%20group%20(10)-1500w.png"
            className="home-image5 thq-img-ratio-1-1"
          />
        </a>
      </div>
      <Features25
        feature1Title={
          <Fragment>
            <span className="home-text150">LP Burnt</span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text151">No Team Allocation</span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text152">No Tax</span>
          </Fragment>
        }
        feature1ImgSrc="/mask%20group%20(47)%20(1)-1400w.png"
        feature1Description={
          <Fragment>
            <span className="home-text153">No rugs in sight. Only melons.</span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text154">Fair lauch on pump.fun</span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="home-text155">No Bullshit - just boobs.</span>
          </Fragment>
        }
        text1={
          <Fragment>
            <span className="home-text156">
              CA: 6yhqRXSYRgToouhjUsBD8rtscJaqKm8rUGLL1Ua7pump
            </span>
          </Fragment>
        }
      ></Features25>
      <Steps2
        step1Title={
          <Fragment>
            <span className="home-text157">Get a Wallet</span>
          </Fragment>
        }
        step2Title={
          <Fragment>
            <span className="home-text158">Get Gas</span>
          </Fragment>
        }
        step3Title={
          <Fragment>
            <span className="home-text159">Ape the Coin</span>
          </Fragment>
        }
        step4Title={
          <Fragment>
            <span className="home-text160">
              <span>Send us Some Titty Pics</span>
              <br></br>
            </span>
          </Fragment>
        }
        step1Description={
          <Fragment>
            <span className="home-text163">
              <span>
                Download phantom wallet from
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <a
                href="https://phantom.app/download"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link6"
              >
                phantom.app
              </a>
            </span>
          </Fragment>
        }
        step2Description={
          <Fragment>
            <span className="home-text165">
              Get some $SOL from a CEX like Binance, Coinbase or Kucoin
            </span>
          </Fragment>
        }
        step3Description={
          <Fragment>
            <span className="home-text166">
              <span>
                Buy the coin on
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text168">pump.fun</span>
            </span>
          </Fragment>
        }
        step4Description={
          <Fragment>
            <span className="home-text169">
              Not totally necessary, but $BOOBA has requested it
            </span>
          </Fragment>
        }
      ></Steps2>
      <Testimonial17
        review1={
          <Fragment>
            <span className="home-text170">
              <span className="home-text171">$BOOBA</span>
              <span>
                {' '}
                kept staring at my boobs. Toxic work environment. #METOO
              </span>
            </span>
          </Fragment>
        }
        review2={
          <Fragment>
            <span className="home-text173">
              I felt a hand on my chest, I look down its a 3 fingered green
              hand. I&apos;m pissed!
            </span>
          </Fragment>
        }
        review3={
          <Fragment>
            <span className="home-text174">
              <span>
                I kept getting these weird poems about my boobs. Found out it
                was
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text176">$BOOBA</span>
              <span> sending them to me. Kinda weird.</span>
            </span>
          </Fragment>
        }
        review4={
          <Fragment>
            <span className="home-text178">
              <span className="home-text179">$BOOBA</span>
              <span> rejected me. He said my boobs were too small.</span>
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text181">
              $BOOBA hasn&apos;t quite figured out how to navigate his passion.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text182">Grievances</span>
          </Fragment>
        }
        author1Name={
          <Fragment>
            <span className="home-text183">Sally Boobsworth</span>
          </Fragment>
        }
        author2Name={
          <Fragment>
            <span className="home-text184">Jane Smith</span>
          </Fragment>
        }
        author3Name={
          <Fragment>
            <span className="home-text185">David Johnson</span>
          </Fragment>
        }
        author4Name={
          <Fragment>
            <span className="home-text186">Sarah Lee</span>
          </Fragment>
        }
        author1Position={
          <Fragment>
            <span className="home-text187">$BOOBA&apos;s Assistant</span>
          </Fragment>
        }
        author2Position={
          <Fragment>
            <span className="home-text188">Meme Connoisseur</span>
          </Fragment>
        }
        author3Position={
          <Fragment>
            <span className="home-text189">Tech Investor</span>
          </Fragment>
        }
        author4Position={
          <Fragment>
            <span className="home-text190">Social Media Influencer</span>
          </Fragment>
        }
      ></Testimonial17>
      <Gallery4
        content1={
          <Fragment>
            <span className="home-text191">
              Discover a collection of hilarious and trending memes created by
              our community members. Get ready to laugh and share the fun with
              others!
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text192">Explore Our Memes Gallery</span>
          </Fragment>
        }
        image1Src="/group%2038%20(3)%20(1)-1400w.png"
        image2Src="/group%2042%20(4)%20(1)-1400w.png"
        image3Src="/group%2044%20(1)-1400w.png"
        gallery4Id="Gallery4"
      ></Gallery4>
      <Footer4
        link3={
          <Fragment>
            <span className="home-text193">Twitter</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="home-text194">Telegram</span>
          </Fragment>
        }
        link5={
          <Fragment>
            <span className="home-text195">Chart</span>
          </Fragment>
        }
        button={
          <Fragment>
            <span className="home-text196">
              <span>
                Buy
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text198">$BOOBA</span>
              <span> Now</span>
            </span>
          </Fragment>
        }
        link31={
          <Fragment>
            <span className="home-text200">Buy</span>
          </Fragment>
        }
        logoSrc="/0xb00ba52a541824e69b78bb4b607e81c0ef24489d_1__1_-removebg-preview%20(3)%20(1)%201%20(1)-1500h.png"
        cookiesLink={
          <Fragment>
            <span className="home-text201">Send Nudes</span>
          </Fragment>
        }
      ></Footer4>
    </div>
  )
}

export default Home
