import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './gallery4.css'

const Gallery4 = (props) => {
  return (
    <div
      id={props.gallery4Id}
      className="gallery4-gallery3 thq-section-padding"
    >
      <div className="gallery4-max-width thq-section-max-width">
        <div className="gallery4-section-title">
          <h2 className="gallery4-text1 thq-heading-2">
            {props.heading1 ?? (
              <Fragment>
                <span className="gallery4-text4">
                  Explore Our Memes Gallery
                </span>
              </Fragment>
            )}
          </h2>
          <span className="gallery4-text2 thq-body-large">
            {props.content1 ?? (
              <Fragment>
                <span className="gallery4-text3">
                  Discover a collection of hilarious and trending memes created
                  by our community members. Get ready to laugh and share the fun
                  with others!
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="gallery4-container1">
          <div className="gallery4-content1">
            <div className="gallery4-container2">
              <img
                alt={props.image1Alt}
                src="/group%2038%20(3)%20(1)-1400w.png"
                className="gallery4-image11"
              />
            </div>
            <div className="gallery4-container3">
              <img
                alt={props.image2Alt}
                src="/group%2042%20(4)%20(1)-1400w.png"
                className="gallery4-image21"
              />
              <img
                alt={props.image3Alt}
                src="/group%2044%20(1)-1400w.png"
                className="gallery4-image31"
              />
            </div>
          </div>
        </div>
        <div className="gallery4-container4">
          <div className="gallery4-content2">
            <div className="gallery4-container5">
              <img
                alt={props.image1Alt1}
                src="/group%2043%20(1)%20(1)-1400w.png"
                className="gallery4-image12"
              />
              <img
                alt={props.imageAlt}
                src={props.imageSrc}
                className="gallery4-image4"
              />
            </div>
            <div className="gallery4-container6">
              <img
                alt={props.image2Alt1}
                src="/group%2039%20(1)-1400w.png"
                className="gallery4-image22"
              />
              <img
                alt={props.image3Alt1}
                src="/group%2034%20(1)-1400w.png"
                className="gallery4-image32"
              />
            </div>
          </div>
        </div>
        <div className="gallery4-container7">
          <div className="gallery4-content3">
            <div className="gallery4-container8">
              <img
                alt={props.image1Alt11}
                src="/2b4%20(1)%201%20(1)-1400w.png"
                className="gallery4-image13"
              />
              <img
                alt={props.imageAlt1}
                src={props.imageSrc1}
                className="gallery4-image5"
              />
            </div>
            <div className="gallery4-container9">
              <img
                alt={props.image2Alt11}
                src="/group%2033%20(1)%20(1)-1400w.png"
                className="gallery4-image23"
              />
              <img
                alt={props.image3Alt11}
                src="/group%2055%20(2)-1400w.png"
                className="gallery4-image33"
              />
              <img
                alt={props.imageAlt2}
                src={props.imageSrc2}
                className="gallery4-image6"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Gallery4.defaultProps = {
  imageSrc: '/group%2041%20(1)-1400w.png',
  image2Alt11: 'Meme Image 2',
  imageSrc2: '/group%2040%20(1)-1400w.png',
  content1: undefined,
  image1Alt: 'Meme Image 1',
  image3Alt11: 'Meme Image 3',
  image1Alt1: 'Meme Image 1',
  imageAlt1: 'image',
  image1Alt11: 'Meme Image 1',
  heading1: undefined,
  imageAlt2: 'image',
  image3Alt: 'Meme Image 3',
  imageAlt: 'image',
  gallery4Id: '',
  image3Alt1: 'Meme Image 3',
  imageSrc1: '/group%2036%20(1)-1400w.png',
  image2Alt: 'Meme Image 2',
  image2Alt1: 'Meme Image 2',
}

Gallery4.propTypes = {
  imageSrc: PropTypes.string,
  image2Alt11: PropTypes.string,
  imageSrc2: PropTypes.string,
  content1: PropTypes.element,
  image1Alt: PropTypes.string,
  image3Alt11: PropTypes.string,
  image1Alt1: PropTypes.string,
  imageAlt1: PropTypes.string,
  image1Alt11: PropTypes.string,
  heading1: PropTypes.element,
  imageAlt2: PropTypes.string,
  image3Alt: PropTypes.string,
  imageAlt: PropTypes.string,
  gallery4Id: PropTypes.string,
  image3Alt1: PropTypes.string,
  imageSrc1: PropTypes.string,
  image2Alt: PropTypes.string,
  image2Alt1: PropTypes.string,
}

export default Gallery4
