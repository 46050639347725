import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './features25.css'

const Features25 = (props) => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div className="features25-container1 thq-section-padding">
      <div className="features25-container2 thq-section-max-width">
        <span className="features25-text1">
          {props.text1 ?? (
            <Fragment>
              <span className="features25-text8">
                CA: 6yhqRXSYRgToouhjUsBD8rtscJaqKm8rUGLL1Ua7pump
              </span>
            </Fragment>
          )}
        </span>
        <div className="features25-container3 thq-section-max-width">
          <div className="features25-tabs-menu">
            <div className="features25-tab-horizontal1">
              <div className="features25-divider-container1">
                {activeTab === 0 && (
                  <div className="features25-container4"></div>
                )}
              </div>
              <div className="features25-content1">
                <h2 className="thq-heading-2">
                  {props.feature1Title ?? (
                    <Fragment>
                      <span className="features25-text5">Unique Memes</span>
                    </Fragment>
                  )}
                </h2>
                <span className="thq-body-small">
                  {props.feature1Description ?? (
                    <Fragment>
                      <span className="features25-text4">
                        Explore a collection of unique and hilarious memes
                        created by our community.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
            <div className="features25-tab-horizontal2">
              <div className="features25-divider-container2">
                {activeTab === 1 && (
                  <div className="features25-container5"></div>
                )}
              </div>
              <div className="features25-content2">
                <h2 className="thq-heading-2">
                  {props.feature2Title ?? (
                    <Fragment>
                      <span className="features25-text6">
                        Real-Time Updates
                      </span>
                    </Fragment>
                  )}
                </h2>
                <span className="thq-body-small">
                  {props.feature2Description ?? (
                    <Fragment>
                      <span className="features25-text2">
                        Stay updated with the latest news and developments about
                        our memecoin in real-time.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
            <div className="features25-tab-horizontal3">
              <div className="features25-divider-container3">
                {activeTab === 2 && (
                  <div className="features25-container6"></div>
                )}
              </div>
              <div className="features25-content3">
                <h2 className="thq-heading-2">
                  {props.feature3Title ?? (
                    <Fragment>
                      <span className="features25-text7">
                        Engaging Community
                      </span>
                    </Fragment>
                  )}
                </h2>
                <span className="thq-body-small">
                  {props.feature3Description ?? (
                    <Fragment>
                      <span className="features25-text3">
                        Join our vibrant community of meme enthusiasts and
                        investors to share ideas and have fun together.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="features25-image-container">
            {activeTab === 0 && (
              <img
                alt={props.feature1ImgAlt}
                src="/mask%20group%20(47)%20(1)-1400w.png"
                className="features25-image1"
              />
            )}
            {activeTab === 1 && (
              <img
                alt={props.feature2ImgAlt}
                src={props.feature2ImgSrc}
                className="features25-image2 thq-img-ratio-16-9"
              />
            )}
            {activeTab === 2 && (
              <img
                alt={props.feature3ImgAlt}
                src={props.feature3ImgSrc}
                className="features25-image3 thq-img-ratio-16-9"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

Features25.defaultProps = {
  feature2Description: undefined,
  feature3Description: undefined,
  feature1Description: undefined,
  feature1ImgAlt: 'Meme Image',
  feature3ImgSrc:
    'https://images.unsplash.com/photo-1634097538301-5d5f8b09eb84?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMzEwOTA0Mnw&ixlib=rb-4.0.3&q=80&w=1400',
  feature1Title: undefined,
  feature2Title: undefined,
  feature2ImgSrc:
    'https://images.unsplash.com/photo-1586880234202-32a56790c681?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMzEwOTA0Mnw&ixlib=rb-4.0.3&q=80&w=1400',
  feature2ImgAlt: 'Clock Icon',
  feature3Title: undefined,
  feature3ImgAlt: 'Community Image',
  text1: undefined,
}

Features25.propTypes = {
  feature2Description: PropTypes.element,
  feature3Description: PropTypes.element,
  feature1Description: PropTypes.element,
  feature1ImgAlt: PropTypes.string,
  feature3ImgSrc: PropTypes.string,
  feature1Title: PropTypes.element,
  feature2Title: PropTypes.element,
  feature2ImgSrc: PropTypes.string,
  feature2ImgAlt: PropTypes.string,
  feature3Title: PropTypes.element,
  feature3ImgAlt: PropTypes.string,
  text1: PropTypes.element,
}

export default Features25
