import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './footer4.css'

const Footer4 = (props) => {
  return (
    <footer className="footer4-footer7 thq-section-padding">
      <div className="footer4-max-width thq-section-max-width">
        <div className="footer4-content">
          <div className="footer4-logo1">
            <img
              alt={props.logoAlt}
              src={props.logoSrc}
              className="footer4-logo2"
            />
          </div>
          <div className="footer4-links">
            <a
              href="https://pump.fun/coin/6yhqRXSYRgToouhjUsBD8rtscJaqKm8rUGLL1Ua7pump"
              target="_blank"
              rel="noreferrer noopener"
              className="thq-body-small"
            >
              {props.link31 ?? (
                <Fragment>
                  <span className="footer4-text17">Buy</span>
                </Fragment>
              )}
            </a>
            <a
              href="https://x.com/aibooba69"
              target="_blank"
              rel="noreferrer noopener"
              className="thq-body-small"
            >
              {props.link3 ?? (
                <Fragment>
                  <span className="footer4-text18">Twitter</span>
                </Fragment>
              )}
            </a>
            <a
              href="https://t.me/joinbooba"
              target="_blank"
              rel="noreferrer noopener"
              className="thq-body-small"
            >
              {props.link4 ?? (
                <Fragment>
                  <span className="footer4-text21">Telegram</span>
                </Fragment>
              )}
            </a>
            <a
              href="https://pump.fun/coin/6yhqRXSYRgToouhjUsBD8rtscJaqKm8rUGLL1Ua7pump"
              target="_blank"
              rel="noreferrer noopener"
              className="thq-body-small"
            >
              {props.link5 ?? (
                <Fragment>
                  <span className="footer4-text19">Chart</span>
                </Fragment>
              )}
            </a>
          </div>
          <a
            href="https://pump.fun/coin/6yhqRXSYRgToouhjUsBD8rtscJaqKm8rUGLL1Ua7pump"
            target="_blank"
            rel="noreferrer noopener"
            className="footer4-link5 thq-button-filled"
          >
            <span>
              {props.button ?? (
                <Fragment>
                  <span className="footer4-text13">
                    <span>
                      Buy
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className="footer4-text15">$BOOBA</span>
                    <span> Now</span>
                  </span>
                </Fragment>
              )}
            </span>
          </a>
        </div>
        <div className="footer4-credits">
          <div className="thq-divider-horizontal"></div>
          <div className="footer4-row">
            <div className="footer4-container">
              <span className="thq-body-small">© 2024 $BOOBA</span>
            </div>
            <div className="footer4-footer-links">
              <a
                href="https://t.me/joinbooba"
                target="_blank"
                rel="noreferrer noopener"
                className="footer4-text12 thq-body-small"
              >
                {props.cookiesLink ?? (
                  <Fragment>
                    <span className="footer4-text20">Send Nudes</span>
                  </Fragment>
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer4.defaultProps = {
  button: undefined,
  link31: undefined,
  link3: undefined,
  logoAlt: 'Memecoin Logo',
  link5: undefined,
  logoSrc: 'https://presentation-website-assets.teleporthq.io/logos/logo.png',
  cookiesLink: undefined,
  link4: undefined,
}

Footer4.propTypes = {
  button: PropTypes.element,
  link31: PropTypes.element,
  link3: PropTypes.element,
  logoAlt: PropTypes.string,
  link5: PropTypes.element,
  logoSrc: PropTypes.string,
  cookiesLink: PropTypes.element,
  link4: PropTypes.element,
}

export default Footer4
